
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import './import-jquery'
import'./jquery-min'
import'@fortawesome/fontawesome-free/css/all.css'
import'../css/fonts.css';
import'../css/form.css';
import'../css/style.css';
import Swiper from 'swiper/swiper-bundle';
import 'swiper/swiper-bundle.css';
import'./form.js'
import'./code.js'






var swiper = new Swiper(".mySwiper_1", {
    spaceBetween: 30,
    loop: true,
    autoHeight: true,
    loopFillGroupWithBlank: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
    }
  });
  var swiper2 = new Swiper(".mySwiper_2", {
    slidesPerView: 1,
    spaceBetween: 10,
    autoplay: {
        delay: 2000
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true
    },
    breakpoints: {
        640: {
            slidesPerView: 1,
            spaceBetween: 20
        },
        768: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        1024: {
            slidesPerView: 3,
            spaceBetween: 30
        }
    }
  });




